/**
 * Get the value of a cookie
 * Source: https://vanillajstoolkit.com/helpers/getcookie/
 * @param name - The name of the cookie
 * @return The cookie value
 */
export function getCookie(name) {
  if (typeof document === "undefined") {
    throw new Error(
      "getCookie() is not supported on the server. Fallback to a different value when rendering on the server."
    );
  }

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts[1].split(";").shift();
  }

  return undefined;
}

const CONSTANTS = {
  DOWNLOAD_BASE_URL: "https://hope-reports.netlify.app/assets/reports/",
  REPORT_YEARS: [2022],
  MONTH_REPORT_PUBLISH_DAY: 5,
  MONTHS: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  REPORTS: {
    2022: {
      year: 2022,
      reports: [
        {
          name: "June 2022",
          fileName: "07_2022.pdf",
        },
      ],
    },
  },
};

function getMonthNumberInTwoDigits(month = 0) {
  if (month < 10) return `0${month}`;
  return `${month}`;
}

function getReportForMonthAndYear(month = 1, year = 2022) {
  const monthName = CONSTANTS.MONTHS[month - 1];
  const fileName = `${getMonthNumberInTwoDigits(month)}_${year}.pdf`;
  const downloadURL = `${CONSTANTS.DOWNLOAD_BASE_URL}${fileName}`;
  const name = `${monthName} ${year}`;
  return {
    name,
    fileName,
    downloadURL,
  };
}

function getMonthsForYear(year) {
  const date = new Date();
  const currentYear = date.getFullYear();
  let monthEndForYear = 12;
  if (currentYear === year) {
    monthEndForYear = date.getMonth() + 1;
  }
  return new Array(monthEndForYear).fill(1).map((_, ind) => ind + 1);
}

const allReports = {};
CONSTANTS.REPORT_YEARS.forEach(function generateReportsData(year) {
  const months = getMonthsForYear(year);
  allReports[year] = {
    year,
    reports: [],
  };
  months.reverse().forEach(function generateReportForMonth(month) {
    allReports[year].reports.push(getReportForMonthAndYear(month, year));
  });
});

export { allReports };
